import { useContext } from "react";
import { ThemeContext } from "../context/themeContext";
import "../styles/navbar.scss";

const Logo = (): JSX.Element => {
	const themeContext: any = useContext(ThemeContext);

	return (
		<div className="logo">
			<img src={require(`${process.env.REACT_APP_LOGO_PATH}${themeContext.theme.logo.id}.png`)} alt="Logo" className="navbar-logo" />
		</div>
	);
};

export default Logo;